import { Button, HStack, Link } from "@chakra-ui/react";
import { BsDiscord, BsTwitter } from "react-icons/bs";


const Footer = () => {
    return (
        <HStack justify="center" spacing={4}>
            <Button colorScheme="teal" size="lg" color="white">
                <Link href="https://twitter.com/ForLootAndGlory" isExternal>
                    <BsTwitter />
                </Link>
            </Button>
            <Button colorScheme="teal" size="lg" color="white">
                <Link href="https://discord.gg/forlootandglory" isExternal>
                    <BsDiscord />
                </Link>
            </Button>
        </HStack>
    );
}

export default Footer;