import { Heading, Image, Text, VStack, Container } from "@chakra-ui/react";
import Coins from '../images/coins.png';
import BackgroundBox from "./backgroundbox";

const PVE = () => {
    return (
        <Container maxW="container.md" borderRadius="md" p={8}>
            <BackgroundBox>
                <VStack spacing={6} align="center">
                    <Image src={Coins} boxSize="150px" />
                    <Heading
                        color="white" size="xl"
                        fontFamily="'Pirata One', cursive"
                        textShadow="1px 1px 2px black"
                        fontWeight="bold">Treasury Hunt</Heading>
                    <Text
                        fontSize="17px"
                        textAlign="center"
                        fontFamily="'Pirata One', cursive"
                        fontWeight="bold"
                        textShadow="1px 1px 2px black"
                        lineHeight="1.6"
                        p={10}>
                        Welcome to the thrilling world of Treasure Hunt!
                        Embark on exciting adventures to collect Coins and gain EXP,
                        which you can use to craft powerful gears in the Forge.
                        Boost your success rates with help from the Brothel and
                        access the Treasure Hunt via Journey. You'll need Bottles of Rum
                        from the Tavern to participate, with varying costs for different hunt levels.
                        Gear up for intense PvP challenges and trade resources on the marketplace.
                        Dive into the excitement and rewards that await you!
                    </Text>
                </VStack>
            </BackgroundBox>
        </Container>
    );
}

export default PVE;