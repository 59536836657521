import { Box, Container, Heading, Image, Text, VStack } from "@chakra-ui/react";
import BackgroundBox from "./backgroundbox";
import Image1 from '../images/rocketEth.png';

const Fees = () => {
    return (
        <Container maxW="container.md" borderRadius="md" p={8}>
        <Box display="flex" justifyContent="center" mt={4}>
            <Image src={Image1} />
        </Box>
            <BackgroundBox>
                <VStack spacing={6} align="center">
                    <Heading
                        color="white" size="xl"
                        fontFamily="'Pirata One', cursive"
                        textShadow="1px 1px 2px black"
                        fontWeight="bold">Fees & Mint</Heading>
                    <Text
                        fontSize="17px"
                        textAlign="center"
                        fontFamily="'Pirata One', cursive"
                        fontWeight="bold"
                        textShadow="1px 1px 2px black"
                        lineHeight="1.6"
                        p={10}>
                        <strong>Economic Impact and Cycle:</strong>
                        <br />
                        Encourages continuous investment and spending for significant rewards. 
                        Designed to perpetuate an ongoing cycle of investment, gameplay, 
                        and reward collection. End-game rewards maintain interest, 
                        regardless of player numbers or asset purchases using Rocket Pool ETH liquid staking token.
                    </Text>
                </VStack>
            </BackgroundBox>
        </Container>
    );
};

export default Fees;