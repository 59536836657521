import { Box } from "@chakra-ui/react";
import ContourImage from '../images/contour.png';
import { ReactNode } from "react";

interface BackgroundBoxProps {
    children: ReactNode;
}

const BackgroundBox: React.FC<BackgroundBoxProps> = ({ children }) => {
    return (
        <Box
            backgroundImage={`url(${ContourImage})`}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            borderRadius="lg"
            p={5}
            boxShadow="lg"
            color="white"
            width="100%"
            maxW="800px"
            minH="600px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-end"
        >
            {children}
        </Box>
    );
};

export default BackgroundBox;