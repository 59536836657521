import { Box, Container, Heading, Image } from "@chakra-ui/react";
import Image1 from '../images/skale.png';

const Partner = () => {
    return (
        <Container maxW="container.md" borderRadius="md" p={8} textAlign="center" >
            <Heading as="h2" size="xl" mb={4} color="white">
                Powered by
            </Heading>
            <Box display="flex" justifyContent="center">
                <Image src={Image1} />
            </Box>
        </Container>
    );
}

export default Partner;