import { Button, HStack, Image, Link, Text, VStack } from "@chakra-ui/react"
import ImgHeading from '../images/mHeading.png';
import Partner from "./partner";
import BackgroundBox from "./backgroundbox";

const MainSection = () => {
  return (
    <VStack spacing={4} textAlign="center">
      <Image src={ImgHeading} alt="Placeholder Image" borderRadius="md" />
      <Partner />
      <BackgroundBox>
        <Text
          fontSize="17px"
          textAlign="center"
          fontFamily="'Pirata One', cursive"
          fontWeight="bold"
          textShadow="1px 1px 2px black"
          lineHeight="1.6"
          p={10}
        >
          Our pirate-themed game is a RPG / strategy game. You will be able to hunt for treasure maps,
          level-up your characters, craft gear and many other things described below. The blockchain used
          is Skale  and all transactions fees is gas free. The first chapter is PVE. It will first be deployed
          on testnet to allow people to actually play the game before investing any cent as we believe it is
          how it should always be instead of selling something without delivering the game first. During this
          phase, a Recruiter NFT can be claimable for free under certain conditions such as connecting to the
          game regularly, completing quests using crew3, tweeting about the project and being active on
          our discord. You will be rewarded with the free-to-mint (with conditions) NFT called the FLAG
          Recruiter NFT. With it, you’ll be able to start your pirate’s journey.
        </Text>
      </BackgroundBox>
      <HStack justify="center" spacing={4}>
        <Button colorScheme="teal" size="lg" color="white">
          <Link href="https://app.forlootandglory.io" isExternal>Polygon App</Link>
        </Button>
        <Button colorScheme="teal" size="lg" color="white">
          <Link href="https://bridge.forlootandglory.io" isExternal>Bridge</Link>
        </Button>
        <Button colorScheme="teal" size="lg" color="white">
          <Link href="https://testnet.forlootandglory.io" isExternal>Skale Game Testnet</Link>
        </Button>
        <Button colorScheme="teal" size="lg" color="white">
          <Link href="https://docs.forlootandglory.io" isExternal>Documentations</Link>
        </Button>
      </HStack>
    </VStack>
  )
}

export default MainSection;