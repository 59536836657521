import { Heading, Image, Text, VStack, Container } from "@chakra-ui/react";
import Image1 from '../images/tavern.png';
import BackgroundBox from "./backgroundbox";

const Burn = () => {
    return (
        <Container maxW="container.md" borderRadius="md" p={8}>
            <BackgroundBox>
                <VStack spacing={6} align="center">
                    <Heading
                        color="white" size="xl"
                        fontFamily="'Pirata One', cursive"
                        textShadow="1px 1px 2px black"
                        fontWeight="bold">Burn</Heading>
                    <Text
                        fontSize="17px"
                        textAlign="center"
                        fontFamily="'Pirata One', cursive"
                        fontWeight="bold"
                        textShadow="1px 1px 2px black"
                        lineHeight="1.6"
                        p={10}>
                        <strong>$BARRELS:</strong> Burned to level up taverns, refill characters' thirst, and for actions in Chapter II. A halving occurs when the circulating supply exceeds 1,000,000.
                        <br />
                        <strong>CHARACTERS:</strong> Required for obtaining legendary items in Chapter II, with a risk of burning. Characters have a limited lifespan and can die in end-game PvP.
                        <br />
                        <strong>GEARS AND STUFF:</strong> When equipped, the gear NFT is burned and cannot be removed. The gear dies with the character.
                        <br />
                        <strong>COINS:</strong> Burned to obtain gears, necessary for end-game and PvP in Chapter II.
                        <br />
                        <strong>TICKETS:</strong> Limited by cooldown and total NFT supply. Balanced by character deaths in end-game and other mechanisms.
                    </Text>
                </VStack>
            </BackgroundBox>
            <Image src={Image1}/>
        </Container>
    );
}

export default Burn;