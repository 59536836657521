import { Heading, Image, Text, VStack, Container, Box } from "@chakra-ui/react";
import Image1 from '../images/flagtoken.png';
import BackgroundBox from "./backgroundbox";

const Token = () => {
    return (
        <Container maxW="container.md" borderRadius="md" p={8} >
            <BackgroundBox>
                <VStack spacing={6} align="center">
                    <Heading
                        color="white" size="xl"
                        fontFamily="'Pirata One', cursive"
                        textShadow="1px 1px 2px black"
                        fontWeight="bold">FLAG Token</Heading>
                    <Text
                        fontSize="17px"
                        textAlign="center"
                        fontFamily="'Pirata One', cursive"
                        fontWeight="bold"
                        textShadow="1px 1px 2px black"
                        lineHeight="1.6"
                        p={10}>
                        FLAG is a token with a maximum supply of 1,000,000 used for
                        member identification, role assignment, and paying royalties.
                        In-game fees are automatically added to the treasury,
                        which adjusts daily emissions to maintain a 5-year distribution.
                        The token can give some premium benefits to stakers, such as
                        airdrop eligibility and staking rewards and in-game discount on fees.
                    </Text>
                </VStack>
            </BackgroundBox>
            <Box display="flex" justifyContent="center" mt={4}>
                <Image src={Image1} boxSize="200px" />
            </Box>
        </Container>
    );
};

export default Token;