import { Heading, Image, Text, VStack, Container } from "@chakra-ui/react";
import Image1 from '../images/tickets.png';
import BackgroundBox from "./backgroundbox";

const Recruiter = () => {
    return (
        <Container maxW="container.md" borderRadius="md" p={8} >
            <BackgroundBox>
                <VStack spacing={6} align="center">
                    <Heading
                        color="white" size="xl"
                        fontFamily="'Pirata One', cursive"
                        textShadow="1px 1px 2px black"
                        fontWeight="bold">Recruiters</Heading>
                    <Text
                        fontSize="17px"
                        textAlign="center"
                        fontFamily="'Pirata One', cursive"
                        fontWeight="bold"
                        textShadow="1px 1px 2px black"
                        lineHeight="1.6"
                        p={10}>
                        The NFT Recruiter is an ERC721 token that allows you to gain access to in-game characters.
                        Its primary function is to enable the minting of tickets, which are then used to mint three in-game characters.
                        While there may be different components and rarity levels for Recruiter NFTs,
                        these distinctions do not affect gameplay. Recruiter NFT holders can mint 3 Tickets (ERC1155) and use them to mint three playable characters.
                        After the initial Ticket mint, a one-month cooldown period will begin, which may vary based on the circulating supply of characters.
                        Each current FLAG token staker receives one PIRATE NFT for every 100 FLAG tokens they stake,
                        and external participants may also have the opportunity to acquire one. For more information on stakers,
                        roles, airdrops, and whitelists, please refer to the provided conditions.
                    </Text>
                </VStack>
            </BackgroundBox>
            <Image src={Image1} />
        </Container>
    );
}

export default Recruiter;