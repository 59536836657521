import { Box, Container, VStack } from '@chakra-ui/react';
import ImageCarousel from './components/carrousel';
import BackgroundImage from './images/background_island.png';
import Footer from './components/footer';
import MainSection from './components/mainsection';
import PVE from './components/pve';
import Characters from './components/characters';
import Recruiter from './components/recruiter';
import Token from './components/token';
import Fees from './components/fees';
import Burn from './components/burn';
//import Earn from './components/earn';
import PVP from './components/pvp';

function LandingPage() {
  return (
    <Box
      minH="100vh"
      backgroundImage={`url(${BackgroundImage})`}
      backgroundSize="cover"
      backgroundAttachment="fixed"
      backgroundPosition="center"
      backgroundColor={'rgba(0, 0, 0, 0.5)'}
    >
      <Container maxW="container.xl" py={8} bgGradient={'linear(to-b, rgba(1, 20, 35, 0.95), rgba(0, 0, 0, 0.1))'}>
        <VStack spacing={8} align="stretch">
          {/* Header */}

          {/* Main Section */}
          <MainSection />

          {/* Image Carousel */}
          <ImageCarousel />

          {/* Recruiter */}
          <Recruiter />

          {/* Token */}
          <Token />

          {/* Characters */}
          <Characters />

          {/* Fees */}
          <Fees />

          {/* PVE */}
          <PVE />

          {/* PVP */}
          <PVP />

          {/* Burn */}
          <Burn />

          {/* Earn & Endgame */}
          {/* <Earn /> */}

          {/* Footer */}
          <Footer />
        </VStack>
      </Container>
    </Box>
  );
}

export default LandingPage;