import { Heading, Image, Text, VStack, Container, Box } from "@chakra-ui/react";
import Image1 from '../images/characters.png';
import BackgroundBox from "./backgroundbox";

const Characters = () => {
    return (
        <Container maxW="container.md" borderRadius="md" p={8} >
            <Box bgColor={'rgb(255,255,255,0.5)'} borderRadius={10}>
                <Image src={Image1} />
            </Box>
            <BackgroundBox>
                <VStack spacing={6} align="center">
                    <Heading
                        color="white" size="xl"
                        fontFamily="'Pirata One', cursive"
                        textShadow="1px 1px 2px black"
                        fontWeight="bold">Characters</Heading>
                    <Text
                        fontSize="17px"
                        textAlign="center"
                        fontFamily="'Pirata One', cursive"
                        fontWeight="bold"
                        textShadow="1px 1px 2px black"
                        lineHeight="1.6"
                        p={5}>
                        In our game, you'll manage your characters by enrolling them in various activities to level up,
                        gain experience, and collect valuable rewards.
                        You'll send your characters on treasure hunts to earn coins and experience points,
                        and equip them with gear from the Forge to enhance their stats.
                        Characters can be assigned to specific careers, such as Blacksmith,
                        Whoremonger, and Shipwright, to contribute to the game's dynamic gameplay.
                        Enjoy an engaging and immersive experience as you navigate through the exciting world of character management and progression.
                    </Text>
                </VStack>
            </BackgroundBox>
        </Container>
    );
}

export default Characters;