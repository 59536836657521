import { Box, Image } from '@chakra-ui/react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image1 from '../images/slide/female30.jpg';
import Image2 from '../images/slide/samp1.jpg';
import Image3 from '../images/slide/samp2.jpg';
import Image4 from '../images/slide/samp3.jpg';
import Image5 from '../images/slide/samp4.jpg';
import Image6 from '../images/slide/samp10.jpg';
import Image7 from '../images/slide/samp24.jpg';
import Image8 from '../images/slide/samp28.jpg';
import Image9 from '../images/slide/samp31.jpg';
import Image10 from '../images/slide/samp32.jpg';
import Image11 from '../images/slide/samp33.jpg';
import Image12 from '../images/slide/samp37.jpg';
import Image13 from '../images/slide/samp44.jpg';
import Image14 from '../images/slide/samp49.jpg';

function ImageCarousel() {

    return (

        <Box padding={10}>
            <Slider
                infinite={true}
                speed={500}
                slidesToShow={5}
                slidesToScroll={1}
                autoplay={true}
            >
                <Box>
                    <Image src={Image1} alt="Image 1" borderRadius="md" />
                </Box>
                <Box>
                    <Image src={Image2} alt="Image 2" borderRadius="md" />
                </Box>
                <Box>
                    <Image src={Image3} alt="Image 3" borderRadius="md" />
                </Box>
                <Box>
                    <Image src={Image4} alt="Image 4" borderRadius="md" />
                </Box>
                <Box>
                    <Image src={Image5} alt="Image 5" borderRadius="md" />
                </Box>
                <Box>
                    <Image src={Image6} alt="Image 6" borderRadius="md" />
                </Box>
                <Box>
                    <Image src={Image7} alt="Image 7" borderRadius="md" />
                </Box>
                <Box>
                    <Image src={Image8} alt="Image 8" borderRadius="md" />
                </Box>
                <Box>
                    <Image src={Image9} alt="Image 9" borderRadius="md" />
                </Box>
                <Box>
                    <Image src={Image10} alt="Image 10" borderRadius="md" />
                </Box>
                <Box>
                    <Image src={Image11} alt="Image 11" borderRadius="md" />
                </Box>
                <Box>
                    <Image src={Image12} alt="Image 12" borderRadius="md" />
                </Box>
                <Box>
                    <Image src={Image13} alt="Image 13" borderRadius="md" />
                </Box>
                <Box>
                    <Image src={Image14} alt="Image 14" borderRadius="md" />
                </Box>

            </Slider>
        </Box>
    );
}

export default ImageCarousel;