import { Heading, Image, Text, VStack, Container } from "@chakra-ui/react";
import Coins from '../images/shipyard.png';
import BackgroundBox from "./backgroundbox";
import ships from '../images/crafter.png';

const PVP = () => {
    return (
        <Container maxW="container.md" borderRadius="md" p={8} >
            <Image src={ships} />
            <BackgroundBox>
                <VStack spacing={6} align="center">
                    <Heading
                        color="white" size="xl"
                        fontFamily="'Pirata One', cursive"
                        textShadow="1px 1px 2px black"
                        fontWeight="bold">PVP</Heading>
                    <Text
                        fontSize="17px"
                        textAlign="center"
                        fontFamily="'Pirata One', cursive"
                        fontWeight="bold"
                        textShadow="1px 1px 2px black"
                        lineHeight="1.6"
                        p={10}>
                        <strong>High-Seas Adventures</strong>
                        <br /><br />
                        In the Player versus Player (PvP) mode, players engage in strategic naval battles to claim treasures and renown.
                        Each Odyssey tests players' resource management, battle skills, and strategic acumen.
                    </Text>
                </VStack>
            </BackgroundBox>
            <Image src={Coins} />
        </Container>
    );
}

export default PVP;